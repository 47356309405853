.ps-carousel {
  position: relative;
  padding-right: 2px;
  .ps-product {
    margin-right: 6px;
    margin-bottom: 5px;
  }

  .slick-arrow {
    @include vertical-align();
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    z-index: 100;
    i {
      font-size: 16px;
    }
    &:hover {
      background-color: #07395c;
      color: white;
    }
    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }
  }

  .slick-dots {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
    text-align: center;
    > li {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
      button {
        display: block;
        vertical-align: top;
        padding: 0;
        font-size: 0;
        border-radius: 0;
        border: none;
        width: 10px;
        height: 10px;
        background-color: rgba(#000, 0.3);
        border-radius: 50%;
      }
      &:last-child {
        margin-right: 0;
      }
      &.slick-active {
        button {
          background-color: $color-1st;
        }
      }
    }
  }

  &.outside {
    @media screen and (min-width: 1366px) {
      .slick-arrow {
        &.slick-prev {
          left: -30px;
        }

        &.slick-next {
          right: -30px;
        }
      }
    }

    @media screen and (min-width: 1440px) {
      .slick-arrow {
        &.slick-prev {
          left: -70px;
        }

        &.slick-next {
          right: -70px;
        }
      }
    }
  }

  &.blur {
    .slick-arrow {
      background-color: rgba(#ccc, 0.5);
      &:hover {
        background-color: $color-1st;
      }
    }
  }
}
