body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-size: 14px;
}

.bd-chat-bot {
  background-color: #07395c;
  font-size: 40px;
  color: white !important;
  border-radius: 50%;
  display: flex;
  width: 75px;
  height: 75px;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.wh-message-box {
  max-width: 350px;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: fixed;
  right: 3%;
  bottom: 16%;
  z-index: 999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.message-head {
  padding: 10px 15px;
  max-width: 350px;
  background-color: #07395c;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.message-box {
  padding: 15px;
  max-width: 350px;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.message-box .message {
  padding: 8px;
  background-color: #eff1f6;
  font-size: 12.5px;
  line-height: 1.3;
  width: 58%;
  border-radius: 5px;
  color: #000;
}

.message-box .right {
  display: flex;
  text-align: right;
  justify-content: right;
}

.message-head i {
  font-size: 20px;
  padding: 10px;
  background-color: white;
  color: #07395c;
  border-radius: 50%;
  display: block;
}

.message-head h5,
.message-head h4 {
  color: white;
}

.price-input {
  padding: 5px 12px;
  border: none;
  background-color: #eff1f6;
  border-radius: 20px;
  font-size: 13px;
  height: 35px;
}

.price-input.price {
  width: 27%;
  text-align: center;
}

.price-input.textarea {
  width: 73%;
  resize: none;
}

.chat-send {
  padding: 10px;
  width: 100%;
  display: block;
  background-color: #07395c;
  color: white !important;
  border-radius: 20px;
  text-align: center;
  border: none;
}

.bd-save {
  padding: 8px;
  font-size: 11.5px;
  display: flex;
  background-color: #07395c;
  color: white !important;
  border-radius: 5px;
  height: 28px;
}

.save-text {
  display: block;
  width: 0;
  overflow: hidden;
  line-height: 0;
}

.bd-save:hover .save-text {
  width: 100%;
  line-height: 1;
  margin-left: 5px;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px #07395c;
  }

  50% {
    box-shadow: 0 0 20px #1d87d3;
  }

  100% {
    box-shadow: 0 0 10px #07395c;
  }
}

@keyframes revolve-glow {
  0% {
    box-shadow: 0 0 10px #1d87d3;
  }
  50% {
    box-shadow: 0 0 20px #1d87d3;
  }
}

.tbl-active {
  animation: revolve-glow 1s infinite alternate;
  transition: border-color 0.5s ease;
}

.dancing-arrow {
  position: absolute;
  top: 18px;
  left: -30px;
  font-size: 22px;
  font-weight: 900;
  animation: bounceLeft 2s infinite;
}

@keyframes bounceLeft {

  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  40% {
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }

  60% {
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
}

@keyframes flow-strip {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

/* editor_content */
.editor_content table th,
.editor_content table td {
  border: 1px solid #888;
  padding: 8px;
}

@media (width<=768px) {
  .editor_content table {
    width: 800px !important;
    overflow: auto;
  }
}

.receipt_modal .ant-modal-close-x {
  display: none;
}