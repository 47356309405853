.spinner-loader {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  place-items: center;
}

.flipping {
  height: 22.4px;
  display: grid;
  grid-template-columns: repeat(5, 22.4px);
  grid-gap: 5.6px;
  /* position: absolute; */
}

.flipping div {
  animation: flipping-owie1ymd 1.0s calc(var(--delay) * 1s) infinite ease;
  background-color: #07395c;
}

.flipping div:nth-of-type(1) {
  --delay: 0.25;
}

.flipping div:nth-of-type(2) {
  --delay: 0.5;
}

.flipping div:nth-of-type(3) {
  --delay: 0.75;
}

.flipping div:nth-of-type(4) {
  --delay: 1;
}

.flipping div:nth-of-type(5) {
  --delay: 1.25;
}

@keyframes flipping-owie1ymd {
  0% {
    transform: perspective(44.8px) rotateY(-180deg);
  }

  50% {
    transform: perspective(44.8px) rotateY(0deg);
  }
}