.blog-details-container {
    /* display: flex; */
}

.blog-content {
    flex: 3;
    padding-right: 20px;
}

.blog-title {
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #07395c;
}

.blog-description {
    font-size: 1.1em;
    color: #555;
    margin-bottom: 20px;
}

.key-points-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.key-points {
    list-style: none;
    padding-left: 0;
    margin-bottom: 30px;
}

.key-points li {
    font-size: 1.1em;
    margin-bottom: 10px;
}

.blog-content-text h3 {
    font-size: 1.6em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.blog-content-text p {
    font-size: 1.1em;
    color: #444;
    line-height: 1.6;
    margin-bottom: 20px;
}

.recent-blogs-sidebar {
    flex: 1;
    padding-left: 20px;
    border-left: 2px solid #ddd;
}

.recent-blogs-sidebar h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.recent-blogs-list {
    list-style: none;
    padding-left: 0;
}

.recent-blogs-list li {
    margin-bottom: 15px;
}

.recent-blogs-list a {
    text-decoration: none;
    font-size: 1.1em;
    color: #007BFF;
}

.recent-blogs-list a:hover {
    text-decoration: underline;
    color: #07395c;
}

@media (width<=768px) {
    .blog-title {
        font-size:1.8em;
        font-weight: bold;
        margin-bottom: 20px;
        color: #07395c;
    }
}