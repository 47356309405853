/* General Job Card Styles */
.job-card {
    width: 100%;
    background-color: #f9f9f9;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.job-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.job-title {
    font-size: 2.2rem;
    font-weight: bold;
}

.job-location {
    font-size: 1.6rem;
    color: #555;
}

.salary {
    font-size: 1.6rem;
    font-weight: bold;
    color: #2d6a4f;
}

.job-card-body {
    padding: 10px 0;
}

.job-description {
    font-size: 1.6rem;
    color: #444;
}

.buttons {
    margin-top: 20px;
    text-align: end;
}

/* .btn {
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: #28a745;
    color: #fff;
    border: none;
  }
  
  .btn-secondary:hover {
    background-color: #218838;
  } */

.full-description {
    margin-top: 10px;
    font-size: 1.6rem;
    color: #333;
}

.full-description p {
    font-size: 1.5rem;
}
 
.job-experience,
.job-work-location {
    margin-top: 25px;
}

.job-benefits ul {
    list-style-type: disc;
    padding-left: 20px;
}

.job-schedule,
.job-experience,
.job-work-location {
    font-size: 1.5rem;
    color: #555;
}

.job-schedule h4,
.job-experience h4,
.job-work-location h4 {
    font-weight: bold;
    color: #007bff;
}

/* Make the layout responsive */
@media (max-width: 768px) {
    .job-card {
        padding: 15px;
    }

    /* .job-title {
      font-size: 1.2rem;
    }
    .salary {
      font-size: 1rem;
    } */
}