.home4-header-prod {
  padding: 16px 20px;
  background-color: #f4f4f4;
}

.all-product-card {
  padding: 15px 20px !important;
  /* border: 1px solid #dee2e6 !important; */
  border-radius: 5px;
  margin: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.all-product-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border: none !important;
  transition: 0.3s all;
}

.Insulation .sub-menu {
  min-width: 180px;
}

.Glues .sub-menu {
  min-width: 180px;
}

.Drywall .sub-menu {
  min-width: 180px;
}

.Concrete .sub-menu {
  min-width: 200px;
  left: -50px !important;
}

.Aggregates .sub-menu {
  min-width: 330px;
}

.Fixings .mega-menu {
  left: -470px !important;
}

.bg-blue {
  background-color: #07395c !important;
}

.color-white {
  color: white !important;
}

.color-blue {
  color: #07395c !important;
}

.header--market-place-1 {
  /* transition: 0.5s all; */
}

.justify-space-between {
  justify-content: space-between;
}

.border-bottom-line {
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.b-head {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background: #fff;
  box-shadow: -1px 3px 27px rgba(0, 0, 0, 0.15);
  z-index: 999 !important;
  animation: fadeInDown 0.8s ease-in-out 0s 1 normal none running;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.btn-ligth-blue {
  padding: 8px 15px;
  background-color: #c3d2db;
  color: #07395c !important;
  border-radius: 3px;
  font-weight: 501;
}

.btn-blue {
  padding: 8px 15px;
  background-color: #07395c;
  color: white !important;
  border-radius: 3px;
  font-weight: 501;
  border: none;
}

.form-select {
  padding: 5px 10px;
  background-color: transparent;
  border: 1px solid #ccc;
  width: 70%;
}

.pd-top-prod {
  padding: 12px 0px;
  border-bottom: 1px solid #07395c;
}

.top-prod {
  background-color: #f1f1f1;
  padding: 15px 0px;
}

.pd-top-prod:last-child {
  border: none;
}

.ps-tab-root .ant-tabs-nav {
  padding: 14px 20px;
  background-color: #f4f4f4;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
  color: #000;
}

.ps-tab-root .ant-tabs-tab {
  padding: 0;
}

.bd-login-page {
  padding-top: 0px;
  max-width: 480px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 30px !important;
  border-radius: 5px;
}

.border-radius3 {
  border-radius: 5px;
}

.bg-light-blue {
  background-color: #1d87d3;
}

.form-forgot {
  margin-bottom: 15px;
}

.no-hover:hover {
  text-decoration: none;
}

.bg-black {
  background-color: black !important;
}

.font-12 {
  font-size: 12px;
}

.bd-head-drop {
  top: 50px;
  right: 5px;
}

.border-top-none {
  border-top: none !important;
  padding-top: 0px !important;
}

.bg-gradient-blue {
  background: linear-gradient(0deg,
      rgba(7, 57, 92, 1) 37%,
      rgba(29, 135, 211, 1) 100%);
}

.bd-eye-open {
  position: absolute;
  top: 15px;
  right: 14px;
}

.bd-eye-open i {
  font-size: 16px;
}

.btn-trash {
  padding: 10px 15px;
  background-color: #07395c;
  border-radius: 50%;
  color: white !important;
}

.btn-trash i {
  font-size: 17px;
}

.ant-modal-footer {
  border-top: none;
  padding: 0;
}

.mb-20 {
  margin-bottom: 15px !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.delivery-type {
  padding: 8px 15px;
  border: 1px solid #07395c;
  border-radius: 3px;
  width: 30%;
}

.delivery-type span {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.ps-product:hover .ps-product__content {
  visibility: visible !important;
  opacity: 1 !important;
}

.no-text:hover {
  text-decoration: none;
}

.ps-section__content ol li {
  margin-bottom: 10px;
}

.h-340 {
  height: 380px;
}

.h-450 {
  height: 430px;
}

.colo-black a {
  color: black;
}

.main-category a {
  font-size: 18px;
  font-weight: 600;
}

.sub-menu-side a {
  font-size: 13.5px;
  font-weight: 400;
}

.term-section {
  /* padding: 30px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border-radius: 8px;
}

.policy-section {
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
}

.policy-section li {
  font-size: 15px;
  margin-bottom: 10px !important;
}

.term-section h3 {
  font-size: 20px;
  font-weight: 550;
}

.term-section li {
  font-size: 14px;
  margin-bottom: 0px !important;
}

.bd-privacy p {
  color: black;
}

.bd-privacy h3 {
  margin-top: 20px;
}

.bd-privacy h1 {
  margin: 30px 0px;
}

.about-us p {
  font-size: 16px;
}

.side-text p {
  font-size: 14px;
}

.custome-checkout {
  max-width: 62%;
}

.custome-order {
  max-width: 38%;
  flex: 0 0 38%;
}

.swal-text {
  text-align: center;
  font-weight: 600;
}

.small-btn {
  padding: 5px;
  width: 30px;
  height: 25px;
  display: inline-block;
  background-color: #1d87d3;
  line-height: 0;
  border-radius: 3px;
  margin-right: 10px;
}

.small-btn-down {
  padding: 5px;
  width: 30px;
  height: 25px;
  display: inline-block;
  background-color: #07395c;
  line-height: 0;
  border-radius: 3px;
  margin-right: 10px;
}

.small-btn-down i {
  margin-left: 2px;
}

.bd-product-dash {
  padding: 16px 16px 0px 16px;
}

.bg-grad-1 {
  background: linear-gradient(90deg,
      rgba(7, 57, 92, 1) 35%,
      rgba(53, 128, 181, 1) 100%);
}

.bg-grad-2 {
  background: linear-gradient(90deg,
      rgba(29, 135, 211, 1) 35%,
      rgba(189, 227, 255, 1) 100%);
}

.bg-grad-3 {
  background: linear-gradient(0deg,
      rgba(7, 57, 92, 1) 37%,
      rgba(29, 135, 211, 1) 100%);
}

.box-shadow-box {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.bd-text-style p {
  font-size: 14.5px;
  color: black;
}

.bd-text-style p span {
  font-weight: 550;
  font-size: 15px;
}

.varition-size {
  margin-bottom: 0px;
  color: black;
  font-weight: 600;
}

.btm-line {
  border-bottom: 1px solid;
  padding-bottom: 5px;
}

.bt-main-total {
  width: 35%;
  font-size: 15px;
}

.mb-05 {
  margin-bottom: 5px;
}

.mobile>i {
  font-size: 21.5px !important;
}

.mobile-drawer .ant-drawer-body {
  padding: 10px;
  padding-left: 25px;
}

.ant-drawer-header {
  padding-left: 40px;
}

.mobile-drawer .ant-menu-inline {
  border: none;
}

.mobile-drawer .ant-menu-item {
  padding-left: 20px !important;
}

.mobile-drawer .ant-menu-item {
  height: 28px !important;
}

.mobile-drawer .-title-content {
  font-size: 15px !important;
}

.mobile-drawer .ant-menu-submenu-title {
  height: 38px !important;
}

.mobile-cart .ps-product__content {
  padding-right: 15px;
  padding-left: 15px;
}

.mobile-cart {
  min-width: 330px !important;
  right: -80px !important;
}

.pagination {
  flex-wrap: wrap;
}

.pagination .page-item {
  margin-bottom: 10px;
}

.bd-top-height {
  height: 433px;
}

.bd-admin-login {
  max-width: 480px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  width: 100%;
}

.login-admin {
  height: 100vh;
  display: flex;
  align-items: center;
}

.price-fixed {
  position: absolute;
  bottom: 5px;
  /* right: 95px; */
  width: 86%;
}

.StripeElement {
  padding: 10px 15px;
  margin-bottom: 20px;
  margin-top: 5px;
  border: 1px solid #ced4da;
}

.mdf-skirting .sub-menu-menu {
  visibility: hidden;
  display: none;
}

.mdf-skirting:hover .sub-menu-menu {
  position: absolute;
  top: 0%;
  left: 100%;
  min-width: 310px;
  z-index: 1000;
  transition: all 0.25s ease;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: visible;
  display: inline-block;
}

.mdf-skirting:hover .sub-menu-menu a {
  display: inline-block;
  padding: 6px 20px;
  width: 100%;
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.mdf-skirting:hover::before {
  content: "";
  width: 13px;
  height: 13px;
  background-color: #fff;
  position: absolute;
  top: 75%;
  left: 100%;
  z-index: 10000;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  transform: rotate(309deg) translate(-50%, -50%);
  transform-origin: 0 0;
}

.sub-menu-skirt {
  padding-left: 30px !important;
  list-style: circle !important;
}

.sub-menu-skirt li {
  padding: 0px;
}

.active-head {
  color: #1d87d3 !important;
  font-weight: 600 !important;
  transition: 0.5s all;
}

.side-active {
  color: #07395c !important;
  font-weight: 650 !important;
  animation: fade-right 1.5s linear infinite;
  /* scale: 1.15; */
}

@keyframes fade-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  75% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
}

@media screen and (min-width: 1660px) and (max-width: 2200px) {
  .container {
    max-width: 90% !important;
  }

  .big-resp {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .menu>li>a {
    padding: 15px 12px;
    font-size: 16px;
  }

  .ps-product .ps-product__thumbnail img {
    height: 200px;
    /* object-fit: none !important; */
    margin-top: 0px;
  }

  .h-340 {
    height: 385px;
  }

  .h-450 {
    height: 460px;
  }
}

@media screen and (min-width: 2200px) {
  .container {
    max-width: 90% !important;
  }

  .big-resp {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .menu>li>a {
    padding: 15px 12px;
    font-size: 16px;
  }

  .h-340 {
    height: 360px;
  }
}

.customised-text {
  font-size: 17px;
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 20px;
}

.bd-about-border {
  border: 40px solid #1d87d3;
  padding: 290px 20px;
  border-radius: 2px;
}

.w-80 {
  width: 70%;
}

.bd-width-img {
  max-width: 46%;
  flex: 0 0 46%;
}

.bd-img-set {
  position: absolute;
  top: 12%;
  right: -140px;
}

.bd-img-set img {
  border-radius: 15px;
  width: 400px;
  object-fit: cover;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.about-text {
  max-width: 54%;
  flex: 0 0 54%;
}

.about-text p {
  font-size: 15px;
  text-align: justify;
  color: black;
}

.about-text h1 {
  font-size: 38px;
  font-weight: 500;
  margin-bottom: 13px;
  padding-bottom: 10px;
  text-decoration: underline;
}

.career-text h5 {
  font-size: 20px;
  margin-bottom: 10px;
}

.career-text h1 {
  font-size: 38px;
  margin-bottom: 10px;
}

.career-text p {
  font-size: 18px;
  margin-bottom: 10px;
}

.career-form h1 {
  font-size: 30px;
}

.career-form input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.17) 0px 1px 3px -1px;
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
}

.career-form textarea {
  box-shadow: rgba(50, 50, 93, 0.05) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.05) 0px 1px 3px -1px;
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
}

.form-bg-blue {
  background-color: #e9f6ff;
}

.bd-second-img {
  position: absolute;
  top: 150px;
  left: 290px;
  object-fit: cover;
  border: 6px solid #7cc6fc;
  padding: 5px;
  background-color: white;
}

.bd-first-img {
  border: 6px solid #7cc6fc;
  padding: 5px;
  background-color: white;
}

.contact-info-service {
  flex: none;
  color: #ced1d5;
  margin: 5px 12px 0 0;
  width: 50px;
  height: 50px;
  background: #223452;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 18px;
}

.contact-service-detail {
  padding: 90px 50px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 550px;
  border-radius: 8px;
  overflow: hidden;
}

.overlay-service {
  background-color: black;
  opacity: 0.75;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.contact-service-detail h1 {
  color: white;
  margin-bottom: 30px;
  font-size: 38px;
}

.contact-service-detail p {
  color: white;
  font-size: 16px;
}

.contact-service-detail h4 {
  font-size: 22px;
  color: white;
}

.box-map-shadow {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.bd-detail-bagde {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #f14705;
}

.btn-location {
  padding: 11px 30px;
  border: none;
  background-color: #07395c;
  border-radius: 3px;
  color: white;
  width: 58%;
  margin: 20px auto 0px;
}

.btn-location:hover {
  text-decoration: none;
  color: white;
}

.bd-category {
  padding: 10px;
  margin-right: 8px;
  font-size: 16px;
  background-color: #a8daff;
  color: black !important;
  border-radius: 3px;
}

.bd-category-active {
  padding: 10px;
  background-color: #07395c;
  margin-right: 8px;
  color: white !important;
  font-size: 16px;
  border-radius: 3px;
}

.bd-img-sets img {
  padding: 20px;
  border-radius: 8px;
  border: 5px solid #07395c;
}

.bd-content-service p {
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 15px;
}

@media screen and (min-width: 1800px) and (max-width: 3000px) {
  .bd-object-fit {
    /* object-fit:none !important; */
  }
}

@media screen and (min-width: 300px) and (max-width: 750px) {
  .mobile-flex {
    flex-direction: column !important;
  }

  .mobile-flex .d-flex {
    flex: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .border-bottom-line {
    margin-bottom: 15px !important;
  }

  .ps-product--detail .ps-product__shopping {
    display: flex;
    padding-bottom: 10px !important;
  }

  .ps-product--detail .ps-product__shopping figure {
    width: 30%;
  }

  .mobile-price h3 {
    font-size: 20px !important;
  }

  .mobile-price {
    margin-bottom: 18px !important;
  }

  .bd-top-height {
    height: 100%;
  }

  .mobile-wrap {
    flex-direction: column-reverse;
  }

  .mobile-width {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .resp-banner {
    height: 100%;
  }

  .ps-breadcrumb {
    display: none;
  }

  .bd-second-img {
    position: relative;
    top: 5px;
    left: 0;
  }

  .bd-custom-text {
    padding-left: 15px !important;
    margin-top: 20px;
  }

  .career-form {
    margin-top: 15px;
  }

  .bd-width-img,
  .about-text {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .bd-img-set {
    position: relative;
    left: 0;
  }

  .bd-img-set img {
    width: 100%;
  }

  .bd-about-border {
    display: none;
  }

  .w-80 {
    width: 100%;
  }

  .mobile-career {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .bd-join {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .ps-table--faqs tr {
    display: flex;
    flex-direction: column;
  }

  .mobile-mt {
    margin-top: 10px !important;
  }

  .mobile-mb {
    margin-bottom: 20px !important;
  }

  .mobile-pb-0 {
    padding-bottom: 0px !important;
  }

  .psearch-4 {
    padding: 5px 10px;
  }

  .ps-section__header.mobile-flex {
    justify-content: left !important;
    align-items: start;
  }

}

.offer-img {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 751px) and (max-width: 2700px) {
  .mobil-search {
    display: none;
  }
}

@media (min-width: 1199px) {
  .mobile-check-deliver {
    display: none;
  }
}

.bd-custome-page {
  background-color: rgb(245, 245, 245);
  color: rgb(0, 0, 0);
  border: medium;
  font-size: 14px;
  margin-right: 10px;
  padding: 0px 14px;
  line-height: 32px;
}

.custom-active {
  color: white !important;
  background-color: #07395c !important;
}

.bd-custome-page:hover {
  color: white;
  background-color: #07395c;
}

.animate {
  position: fixed;
  bottom: 18px;
  right: 24px;
  width: 60px;
  height: 60px;
  background-color: transparent;

  border-radius: 50px;
  box-shadow: 0 0 60px 30px #fff, 0 0 100px 60px #b400ff, 0 0 140px 90px #ff00d4;
  animation: glowing 1s ease-in-out infinite alternate;
}

@keyframes glowing {
  from {
    box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #1d87d3, 0 0 40px #0ff,
      0 0 50px #1d87d3, 0 0 60px #1d87d3, 0 0 70px #1d87d3;
  }

  to {
    box-shadow: 0 0 20px #fff, 0 0 30px #1d87d3, 0 0 40px #1d87d3,
      0 0 50px #1d87d3, 0 0 60px #1d87d3, 0 0 70px #1d87d3, 0 0 80px #1d87d3;
  }
}

.bg-nego-light-blue {
  background-color: #f0f9ff;
}

.boder-top {
  border-top: 1px solid black;
}

.bd-font-cart>div {
  font-size: 18px;
}

.checkout-nego {
  background-color: #eff8ff;
}

.checkout-nonnego {
  background-color: #d9f0ff;
}

.checkout-total {
  background-color: #b4e0ff;
}

.checkout-discount {
  background-color: green;
}

.font-blue {
  color: #07395c;
  font-weight: 600;
}

.empty-cart {
  padding: 8px 15px;
  background-color: #07395c;
  color: white !important;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  display: flex;
  cursor: pointer;
  transition: transform 0.3s ease;
  align-items: center;
}

.pd-text {
  opacity: 0;
  visibility: hidden;
  color: white;
  display: inline-block;
  width: 0;
  overflow: hidden;
  height: 0px;
  font-size: 14px;
  line-height: 1.1;
}

.empty-cart:hover .pd-text {
  opacity: 1;
  visibility: visible;
  width: auto;
  height: auto;
  margin-left: 5px;
}

.ps-panel__content {
  scrollbar-width: thin;
}

.red-mark td {
  color: #fc5363 !important;
}

.ps-delivery-status {
  display: flex;
}

.ps-delivery-status input {
  width: 65%;
  padding: 8px 10px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 13px;

}

.ps-delivery-status button {
  border: none;
  background-color: #07395c;
  padding: 3px 10px;
  color: white;
  font-size: 13px;
  width: 100px;
}

.gap-3 {
  gap: 35px;
}

.check .ant-spin-dot-item {
  background-color: white;
}

.offer-badge {
  clip-path: polygon(28% -5.33%, 105.17% -6.33%, 101.72% 96%, 27.55% 103.67%, -2px 55.33%);
  padding: 5px 10px 5px 31px !important;
  border-radius: 1px !important;
}

/* out of stock css */
.ribbon-2 {
  --f: 10px;
  /* control the folded part*/
  --r: 15px;
  /* control the ribbon shape */
  --t: 38px;
  /* the top offset */
  right: -3px !important;
  position: absolute;
  inset: var(--t) calc(-1*var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path:
    polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
      calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
      var(--r) calc(50% - var(--f)/2));
  background: #07395c;
  box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
  z-index: 99;
  color: white;
  font-size: 12px;
}

.font-white {
  color: white !important;
}

.social-icon {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  display: flex !important;
  height: 30px;
  justify-content: center;
  width: 30px;
}
.social-icon .facebook {
  color: #3b5998;
}
.social-icon .instagram  {
  color: #c32aa3;
}
.gap-2{
  gap: 15px;
}

/* blogs */
.blog_card{
  background-color: #f1f1f1;
  min-height: 470px; 
}
.blog_card img{
  width: 100%;
  height: 260px;
  object-fit: cover;
}
.blog_card a{
  color: #1d87d3;
  font-size: 16px;
  font-weight: 500 !important;
}

/* home */
.head_line{
  font-size: 20px;
  text-align: center;
  color: #f1f1f1;
  font-weight: 500;
  background-color: rgb(203, 61, 61);
  /* background: linear-gradient(170deg, #a51c3e, #0d5064);; */
}