.pagination {
  margin: 0;
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 0;

  li {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    > a {
      padding: 0 14px;
      position: relative;
      display: inline-block;
      z-index: 30;
      color: #999;
      line-height: 32px;
      border: none;
      background-color: #f5f5f5;
      color: $color-heading;
      vertical-align: middle;
      font-size: 14px;

      i {
        font-size: 10px;
        font-weight: 600;
        margin-left: 4px;
      }
      &:hover {
        background-color: #07395c;
        color: white;
      }
    }

    &.active {
      border: none;
      a {
        background-color: #07395c;
        color: white;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.ps-pagination {
  padding-top: 70px;
  text-align: center;
  @include clearfix;

  .ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  @include media("<sm") {
    .pagination {
      > li {
        margin-bottom: 10px;
        > a {
          min-width: 40px;
          line-height: 40px;
          font-size: 1.4rem;
        }
      }
    }
  }
}
